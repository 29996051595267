/* You can add global styles to this file, and also import other style files */
/* @import "../node_modules/ng2-slim-loading-bar/style.css";   */

.container{
  max-width: 1440px;
}

.pointer{
  cursor: pointer;
}


